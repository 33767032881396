/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import { ADVANCED_QUOTE_FORM } from 'Component/AdvancedQuoteForm/AdvancedQuoteForm.config';
import {
    BRAND_SHIPPING_METHOD_CODE
} from 'Component/CheckoutDeliveryOptions/CheckoutDeliveryOptions.config';
import { DISCONTINUED_FORM } from 'Component/DiscontinuedForm/DiscontinuedForm.config';
import { PRICE_REQUEST_FORM } from 'Component/PriceRequestForm/PriceRequestForm.config';
import { QUOTE_FORM } from 'Component/QuoteForm/QuoteForm.config';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import {
    Form as SourceForm
} from 'SourceComponent/Form/Form.component';
import { BILLING_STEP, SHIPPING_STEP } from 'SourceRoute/Checkout/Checkout.config';
import { customerType } from 'Type/Account';
import { countriesType } from 'Type/Config';

/** @namespace ZnetPwa/Component/Form/Component/FormComponent */
export class FormComponent extends SourceForm {
    static propTypes = {
        ...this.props,
        isSameAsShipping: PropTypes.bool,
        isZipAndStateMismatch: PropTypes.func.isRequired,
        getAddressById: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        selectedCustomerAddressId: PropTypes.number.isRequired,
        countries: countriesType.isRequired,
        setInstallationError: PropTypes.func.isRequired,
        setPickupAcknowledgementError: PropTypes.func.isRequired,
        setPickupLocationError: PropTypes.func.isRequired,
        setCurbsideDisclaimerError: PropTypes.func.isRequired,
        setDeliveryDisclaimerError: PropTypes.func.isRequired,
        isExistingInstallation: PropTypes.bool.isRequired,
        isPickupAcknowledge: PropTypes.bool.isRequired,
        isCurbsideDisclaimer: PropTypes.bool.isRequired,
        isDeliveryDisclaimer: PropTypes.bool.isRequired,
        shippingMethod: PropTypes.string.isRequired,
        shippingFields: PropTypes.oneOfType([
            PropTypes.object
        ]),
        isTriggerError: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isTriggerError: false
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const {
            isTriggerError
        } = this.props;

        /** Trigger all required field errors to appear */
        if (isTriggerError) {
            this.triggerErrors();
        }
    }

    triggerErrors = async () => {
        const {
            shippingMethod,
            shippingFields,
            isPickupAcknowledge,
            isCurbsideDisclaimer,
            isDeliveryDisclaimer,
            isExistingInstallation,
            setInstallationError,
            setPickupAcknowledgementError,
            setPickupLocationError,
            setCurbsideDisclaimerError,
            setDeliveryDisclaimerError,
            id
        } = this.props;

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());

        if (id === SHIPPING_STEP) {
            // Setting custome error for flooring installation as 2 checboxes are not part of the fieldMap
            if (isExistingInstallation === null) {
                // If there is one error left, scroll. Check if that's installation error is done in other place
                const scrollToError = invalidFields.length === 1;
                setInstallationError(scrollToError);
                return;
            }

            if (shippingMethod === BRAND_SHIPPING_METHOD_CODE) {
                // Custom error for brand shipping checkbox
                if (isCurbsideDisclaimer === false) {
                    setCurbsideDisclaimerError(true);
                    return;
                }

                if (isDeliveryDisclaimer === false) {
                    setDeliveryDisclaimerError(true);
                    return;
                }
            }
        }

        if (id === SHIPPING_STEP && shippingMethod === STORE_IN_PICK_UP_METHOD_CODE) {
            // Check if store address is selected for pickup method
            const selectedStoreAddress = shippingFields?.selectedStoreAddress || inputValues.selectedStoreAddress;
            const currentShippingMethod = shippingFields?.selectedShippingMethod;

            // Trigger error if:
            // 1. No store address is selected at all, OR
            // 2. The current shipping method doesn't match the shipping method used when selecting the store location
            const noStoreAddress = !selectedStoreAddress || Object.keys(selectedStoreAddress).length === 0;
            const isMethodMismatch = currentShippingMethod
                && currentShippingMethod.method_code === STORE_IN_PICK_UP_METHOD_CODE
                && (!selectedStoreAddress?.shipping_method
                || selectedStoreAddress?.shipping_method
                !== `${currentShippingMethod.carrier_code}_${currentShippingMethod.method_code}`);

            if (noStoreAddress || isMethodMismatch) {
                setPickupLocationError(true);
                return;
            }

            // Custom error for pickup checkbox
            if (isPickupAcknowledge === false) {
                setPickupAcknowledgementError();
            }
        }
    };

    handleFormSubmit = async (e) => {
        const {
            isSameAsShipping,
            getAddressById,
            selectedCustomerAddressId,
            isZipAndStateMismatch,
            countries,
            shippingFields,
            shippingMethod,
            isPickupAcknowledge,
            isCurbsideDisclaimer,
            isDeliveryDisclaimer,
            isExistingInstallation,
            setInstallationError,
            setPickupAcknowledgementError,
            setPickupLocationError,
            setCurbsideDisclaimerError,
            setDeliveryDisclaimerError,
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id
        } = this.props;

        e.preventDefault();
        onSubmit();

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());

        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        if (id === SHIPPING_STEP) {
            let shouldReturn = false;
            // Setting custom error for flooring installation as 2 checkboxes are not part of the fieldMap
            if (isExistingInstallation === null) {
                // If there is one error left, scroll. Check if that's installation error is done in other place
                const scrollToError = invalidFields.length === 1;
                setInstallationError(scrollToError);
                shouldReturn = true;
            }

            if (shippingMethod === BRAND_SHIPPING_METHOD_CODE) {
                // Custom error for brand shipping checkbox
                if (isCurbsideDisclaimer === false) {
                    setCurbsideDisclaimerError(true);
                    return;
                }

                if (isDeliveryDisclaimer === false) {
                    setDeliveryDisclaimerError(true);
                    return;
                }
            }

            if (shouldReturn) {
                return;
            }
        }

        if (id === SHIPPING_STEP && shippingMethod === STORE_IN_PICK_UP_METHOD_CODE) {
            // Check if store address is selected for pickup method
            const selectedStoreAddress = shippingFields?.selectedStoreAddress || inputValues.selectedStoreAddress;
            const currentShippingMethod = shippingFields?.selectedShippingMethod;

            // Trigger error if:
            // 1. No store address is selected at all, OR
            // 2. The current shipping method doesn't match the shipping method used when selecting the store location
            const noStoreAddress = !selectedStoreAddress || Object.keys(selectedStoreAddress).length === 0;
            const isMethodMismatch = currentShippingMethod
                && currentShippingMethod.method_code === STORE_IN_PICK_UP_METHOD_CODE
                && (!selectedStoreAddress?.shipping_method
                || selectedStoreAddress?.shipping_method
                !== `${currentShippingMethod.carrier_code}_${currentShippingMethod.method_code}`);

            if (noStoreAddress || isMethodMismatch) {
                setPickupLocationError(true);
                return;
            }

            // Custom error for pickup checkbox
            if (isPickupAcknowledge === false) {
                setPickupAcknowledgementError();
                return;
            }
        }

        if (id === SHIPPING_STEP || id === BILLING_STEP) {
            const {
                country_id,
                postcode,
                region_id
            } = inputValues;
            const regions = countries.find((country) => country.id === country_id)?.available_regions;
            const region = regions && regions.length
                ? regions.find((region) => region.id === parseInt(region_id, 10))
                : {};

            if (country_id && postcode) {
                if (await isZipAndStateMismatch(country_id, postcode, region?.code)) {
                    return;
                }
            } else if ((!country_id || !postcode) && selectedCustomerAddressId !== undefined && !isSameAsShipping) {
                const address = getAddressById(selectedCustomerAddressId);
                const {
                    country_id,
                    postcode,
                    region_id
                } = address;
                const regions = countries.find((country) => country.id === country_id)?.available_regions;
                const region = regions && regions.length
                    ? regions.find((region) => region.id === parseInt(region_id, 10))
                    : {};

                if (await isZipAndStateMismatch(country_id, postcode, region?.code)) {
                    return;
                }
            }
        }

        asyncData.then(
            /** @namespace ZnetPwa/Component/Form/Component/then */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    onSubmitSuccess(inputValues, asyncDataList);
                    return;
                }

                onSubmitError(inputValues, invalidFields);
            },
            /** @namespace ZnetPwa/Component/Form/Component/then */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };

    collectFieldsInformation = () => {
        const { refMap } = this.state;
        const { children: propsChildren, id } = this.props;

        const {
            children,
            fieldsAreValid,
            invalidFields
        } = FormComponent.cloneAndValidateChildren(propsChildren, refMap);

        this.setState({ children, fieldsAreValid });

        const inputValues = Object.values(refMap).reduce((inputValues, input) => {
            const { current } = input;
            if (current && current.id && current.value) {
                const { name, value, checked } = current;

                if (current.dataset.skipValue === 'true') {
                    return inputValues;
                }

                if (current.type === 'checkbox') {
                    const boolValue = checked;
                    return { ...inputValues, [name]: boolValue };
                }

                return { ...inputValues, [name]: value };
            }

            return inputValues;
        }, {});

        if (invalidFields.length && id !== QUOTE_FORM
            && id !== ADVANCED_QUOTE_FORM && id !== PRICE_REQUEST_FORM && id !== DISCONTINUED_FORM) {
            const { current } = refMap[invalidFields[0]];

            if (id === BILLING_STEP) {
                current?.scrollIntoView({
                    block: 'center'
                });
            } else {
                current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }

        return {
            inputValues,
            invalidFields
        };
    };
}

export default FormComponent;
