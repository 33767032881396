import PropTypes from 'prop-types';

import ImageComponent from 'Component/Image/Image.component';
import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
import { isCloudinaryImage } from 'Util/Image/Image';

import './ImageWebp.style.scss';

/** @namespace ZnetPwa/Component/ImageWebp/Component/ImageWebpComponent */
export class ImageWebpComponent extends ImageComponent {
    static propTypes = {
        ...this.propTypes,
        isBlog: PropTypes.bool.isRequired,
        placeholderImageUrl: PropTypes.string.isRequired
    };

    onImageError = this.onImageError.bind(this);

    state = {
        ...this.state,
        isImageError: false,
        isWebpError: false
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onImageError(e) {
        const { placeholderImageUrl } = this.props;
        const { isWebpError } = this.state;
        const errorImage = e.target;

        errorImage.onError = null;
        errorImage.src = placeholderImageUrl;

        if (!isWebpError) {
            this.setState({ isWebpError: true });
        } else {
            this.setState({ imageStatus: IMAGE_NOT_FOUND, isImageError: true });
        }
    }

    renderPicture(src = '', alt, imageStatus, style, title) {
        const { loading = 'lazy', placeholderImageUrl } = this.props;
        const { isWebpError, isImageError } = this.state;
        const dotIndex = src.lastIndexOf('.');
        if (!isCloudinaryImage(src) && dotIndex !== -1 && !isWebpError) {
            const webpSrc = `${src.substring(0, dotIndex)}.webp`;

            return (
                <picture
                  block="ImageWebp"
                  elem="Content"
                >
                    <source srcSet={ webpSrc } type="image/webp" />
                    <source srcSet={ src } type="image/jpeg" />
                    <img
                      block="Image"
                      elem="Image"
                      src={ src || '' }
                      alt={ alt }
                      mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                      style={ style }
                      title={ title }
                      onLoad={ this.onLoad }
                      onError={ this.onImageError }
                      loading={ loading }
                    />
                </picture>
            );
        }

        if (!isImageError) {
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ this.onLoad }
                  onError={ this.onImageError }
                  loading={ loading }
                />
            );
        }

        return (
            <img
              block="Image"
              elem="Image"
              src={ placeholderImageUrl || '' }
              alt={ alt }
              mods={ { isLoading: imageStatus === IMAGE_LOADING } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onImageError }
              loading={ loading }
            />
        );
    }

    renderImage() {
        const {
            alt,
            isPlaceholder,
            src,
            style,
            title
        } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderPicture(src, alt, imageStatus, style, title);
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return this.renderPicture(src, alt, imageStatus, style, title);
        default:
            return null;
        }
    }

    render() {
        const {
            isBlog,
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className
        } = this.props;

        const { imageStatus } = this.state;

        return (
            <div
              block="Image"
              ref={ imageRef }
              mods={ {
                  isBlog,
                  ratio,
                  imageStatus,
                  isPlaceholder,
                  hasSrc: !!src,
                  imageWebp: true
              } }
              mix={ mix }
              style={ wrapperSize }
              // eslint-disable-next-line react/forbid-dom-props
              className={ className }
            >
                { this.renderImage() }
            </div>
        );
    }
}

export default ImageWebpComponent;
