import { CONFIGURABLE } from '@scandipwa/scandipwa/src/util/Product';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import ImageWebp from 'Component/ImageWebp';

import './ProductPreviewAndQuantity.style';

/** @namespace ZnetPwa/Component/ProductPreviewAndQuantity/Component/ProductPreviewAndQuantityComponent */
export class ProductPreviewAndQuantityComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        qtyInputRef: PropTypes.object.isRequired,
        quantity: PropTypes.number.isRequired,
        imageUrl: PropTypes.string.isRequired,
        collectionColor: PropTypes.string.isRequired,
        collectionName: PropTypes.string.isRequired,
        brandPlankWidth: PropTypes.string.isRequired,
        coverageAreaType: PropTypes.string.isRequired,
        minQuantity: PropTypes.number.isRequired,
        maxQuantity: PropTypes.number.isRequired,
        setQuantity: PropTypes.func.isRequired,
        totalArea: PropTypes.string.isRequired,
        productType: PropTypes.string,
        alt: PropTypes.string.isRequired
    };

    static defaultProps = {
        productType: ''
    };

    renderCoverage() {
        const {
            productType,
            totalArea,
            coverageAreaType
        } = this.props;

        if (productType === CONFIGURABLE) {
            return null;
        }

        return (
            <span block="ProductPreviewAndQuantity" elem="BoxCoverage">
                { `${ totalArea } ${ coverageAreaType }` }
            </span>
        );
    }

    render() {
        const {
            alt,
            qtyInputRef,
            quantity,
            imageUrl,
            collectionColor,
            collectionName,
            brandPlankWidth,
            minQuantity,
            maxQuantity,
            setQuantity
        } = this.props;

        return (
            <div
              block="ProductPreviewAndQuantity"
              elem="QtyReview"
            >
                <ImageWebp
                  src={ imageUrl }
                  mix={ {
                      block: 'ProductPreviewAndQuantity',
                      elem: 'ProductImage'
                  } }
                  ratio="custom"
                  style={ { objectFit: 'cover' } }
                  alt={ alt }
                />
                <div
                  block="ProductPreviewAndQuantity"
                  elem="ProductDetails"
                >
                    <p>{ collectionColor }</p>
                    <p>{ brandPlankWidth }</p>
                    <p>{ collectionName }</p>
                </div>
                <div block="ProductPreviewAndQuantity" elem="QtyWrapper">
                    <Field
                      ref={ qtyInputRef }
                      id="AddToCartQty"
                      name="AddToCartQty"
                      type="number"
                      value={ quantity }
                      max={ maxQuantity }
                      min={ minQuantity }
                      onChange={ setQuantity }
                      allowEmptyValue
                      mix={ { block: 'ProductPreviewAndQuantity', elem: 'QtyInput' } }
                    />
                    { this.renderCoverage() }
                </div>
            </div>
        );
    }
}

export default ProductPreviewAndQuantityComponent;
