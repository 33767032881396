import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CUSTOMER_ACCOUNT, FORGOT_PASSWORD, REGISTER } from 'Component/Header/Header.config';
import {
    mapStateToProps
} from 'Component/MyAccountOverlay/MyAccountOverlay.container';
import {
    LoginAccountContainer as SourceLoginAccountContainer,
    mapDispatchToProps as sourceMapDispatchToProps
} from 'SourceRoute/LoginAccount/LoginAccount.container';
import { updateMeta } from 'Store/Meta/Meta.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import {
    urlToSearchState
} from 'Util/SearchSpring';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace ZnetPwa/Route/LoginAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace ZnetPwa/Route/LoginAccount/Container/LoginAccountContainer */
export class LoginAccountContainer extends SourceLoginAccountContainer {
    static propTypes = {
        ...this.propTypes,
        isPopup: PropTypes.bool,
        updateMeta: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isPopup: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        onSignIn: this.onSignIn.bind(this)
    };

    componentDidMount() {
        const {
            setHeaderState,
            toggleBreadcrumbs,
            updateMeta,
            isPopup
        } = this.props;

        if (isSignedIn()) {
            history.push(appendWithStoreCode('/'));
        }

        setHeaderState({ name: CUSTOMER_ACCOUNT, title: __('Sign in') });
        toggleBreadcrumbs(false);

        if (!isPopup) {
            if (window.location.pathname.includes('referer')) {
                updateMeta({
                    robots: 'noindex, nofollow'
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPopup } = this.props;
        if (isSignedIn()) {
            const { hideActiveOverlay } = this.props;
            hideActiveOverlay();

            if (!isPopup) {
                if (window.location.pathname.includes('/account/login')
                    || window.location.pathname.includes('/customer/account/login')) {
                    // Make sure history is defined before using push
                    if (history && typeof history.push === 'function') {
                        history.push('/');
                    } else {
                    // Fallback if history.push is not available
                        window.location.href = '/';
                    }
                }
            }

            return;
        }

        super.componentDidUpdate(prevProps, prevState);
    }

    handleForgotPassword() {
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
        history.replace(appendWithStoreCode(`${ FORGOT_PASSWORD }`));
    }

    onCreateAccountClick() {
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
        history.replace(appendWithStoreCode(`${ REGISTER }`));
    }

    onSignIn() {
        const { onSignIn, location } = this.props;
        onSignIn();

        if (location?.state) {
            history.push(appendWithStoreCode(location.state));
        }

        if (location?.search) {
            const { redirect } = urlToSearchState(location);

            if (redirect) {
                history.push(appendWithStoreCode(redirect));
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAccountContainer);
