/** Change image extension to .webp or use the original URL if not extension passed
 *  @param {String} src
 *  @returns {String}
*/
/** @namespace ZnetPwa/Util/Image/getWebPUrl */
export const getWebPUrl = (src) => {
    const dotIndex = src.lastIndexOf('.');

    if (dotIndex !== -1) {
        return `${src.substring(0, dotIndex)}.webp`;
    }

    return src;
};

/** Check if webp is supported in the browser for JS purposes
 *  @param {}
 *  @returns {boolean}
 */
/** @namespace ZnetPwa/Util/Image/isWebPSupported */
export const isWebPSupported = () => {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
};

/**
 * Check if the image is a local image
 *
 * @param   {string}  baseUrl
 * @param   {string}  imageUrl
 * @returns {boolean}
 * {@link   https://sepoy.atlassian.net/browse/ZFR-1765}
 */
/** @namespace ZnetPwa/Util/Image/isLocalImage */
export const isLocalImage = (baseUrl, imageUrl) => imageUrl.indexOf(baseUrl) === 0;

/**
 * Check if the image is a local image
 *
 * @param   {string}  baseUrl
 * @param   {string}  imageUrl
 * @returns {boolean}
 * @see     \Cloudinary\Cloudinary\Core\CloudinaryImageProvider::retrieveTransformed()
 * {@link   https://sepoy.atlassian.net/browse/ZFR-1765}
 * @note    It was decided to use this utility instead of `isLocalImage()`
 *          because other environments use production URL in some images,
 *          so the validation does not work as expected
 */
/** @namespace ZnetPwa/Util/Image/isCloudinaryImage */
export const isCloudinaryImage = (imageUrl) => imageUrl.indexOf('cloudinary.com') !== -1;
